<template>
  <div class="content">
    <div class="box">
      <div class="box-field">
        <van-field placeholder="请输入实物兑换码" v-model="redeemCodeNo" />
        <van-button
          :loading="loading"
          loading-text="查看中..."
          @click="doSearch()"
        >
          查询
        </van-button>
      </div>
      <div class="box-status">
        <div>
          发货状态：{{
            [0].includes(logisticData.issueStatus)
              ? '待发货'
              : [1].includes(logisticData.issueStatus)
              ? '已发货'
              : '暂无'
          }}
        </div>
        <a href="https://www.kuaidi100.com" target="_blank">查看物流</a>
      </div>
      <div class="box-status" v-show="logisticData.logisticsNo">
        <div>物流单号：{{ logisticData.logisticsNo || '暂无' }}</div>
        <a
          v-clipboard:copy="logisticData.logisticsNo"
          v-clipboard:success="copySuccess"
          v-clipboard:error="copyError"
        >
          复制
        </a>
      </div>
    </div>
    <div class="tips">
      <h3>操作提示</h3>
      <p>1、输入已兑换的实物兑换码，查询发货信息；</p>
      <p>2、复制物流单号，点击查看物流详情填入单号进行查询；</p>
    </div>
  </div>
</template>

<script>
import { getLogistics } from '@/api/PhysicalRedeemCode.js'
export default {
  data() {
    return {
      loading: false,
      redeemCodeNo: undefined,
      logisticData: {}
    }
  },
  methods: {
    async doSearch() {
      const redeemCodeNo = this.redeemCodeNo
      if (!redeemCodeNo) {
        this.$toast({
          message: '请填写实物兑换码'
        })
        return
      }
      const { code, data, message } = await getLogistics({ redeemCodeNo })
      if (code === 0) {
        this.logisticData = data
      } else {
        this.$toast({
          message: message
        })
      }
    },
    copySuccess() {
      this.$toast({
        message: '复制成功'
      })
    },
    copyError() {
      this.$toast({
        message: '复制失败'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  min-height: 100vh;
  background: #eef3ff;
  padding: 50px 40px;
  .box {
    background: #ffffff;
    box-shadow: 0px 10px 30px 0px rgba(131, 169, 203, 0.2);
    border-radius: 30px;
    padding: 64px 20px 44px;
    &-field {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      border-bottom: solid 1px #dae0e6;
      padding-right: 20px;
      margin-bottom: 25px;
      .van-cell::after {
        border-bottom: none;
      }
      .van-button {
        width: 130px;
        height: 72px;
        background: #367aeb;
        border-radius: 32px;
        font-size: 26px;
        color: #ffffff;
        line-height: 72px;
      }
    }
    &-status {
      display: flex;
      font-size: 24px;
      color: #212224;
      padding: 25px 20px;
      a {
        color: #367aeb;
        flex: 1;
        text-align: right;
      }
    }
  }
  .tips {
    font-size: 24px;
    color: #686e79;
    padding: 103px 40px 79px 38px;
    h3 {
      font-weight: 600;
      font-size: 28px;
      color: #212224;
      position: relative;
      padding-left: 40px;
      padding-bottom: 48px;
      &::before {
        position: absolute;
        left: 0;
        top: 5px;
        content: '';
        background: url('~@/assets/img/PhysicalRedeemCode/tips.png') center
          center;
        background-size: contain;
        width: 25px;
        height: 30px;
      }
    }
    p {
      padding-bottom: 30px;
    }
  }
}
</style>
